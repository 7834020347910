import { Box, Divider } from '@mui/material'
import { Roles } from '../../../../utils/enums/enums'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import CustomerAPIKeySection from './CustomerAPIKeySection/CustomerAPIKeySection'
import './CommunicationManagement.scss'
import RoleCommunicationSettings from './RoleCommunicationSettings/RoleCommunicationSettings'

function CommunicationManagement() {
	// Global
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)

	return (
		<Box className='comm-mgt-container'>
			{/* Customer API Key Generation section */}
			{roleID === Roles.CustomerAdmin && (
				<>
					<CustomerAPIKeySection />
					<Divider />
				</>
			)}
			{/* Email Communications */}
			<RoleCommunicationSettings />
		</Box>
	)
}

export default CommunicationManagement
