import {
	Box,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	IconButton,
	InputAdornment,
	Skeleton,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import UseCrud from '../../../../../utils/customHooks/APICalls/UseCrud'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import {
	ContactDetailTypes,
	FormChangeStatus,
	Partners,
	ReturnTypes,
	Roles,
} from '../../../../../utils/enums/enums'
import { DataResponse } from '../../../../../utils/interfaces/APIModels'
import {
	ContactDetail,
	ContactDetailMap,
} from '../../../../../utils/interfaces/DBModels'
import { RoleCommunicationEmails } from '../../../../../utils/interfaces/ComponentModels'
import { useEffect, useState } from 'react'
import { StyledTextField } from '../../../../../styles/styledComponents/inputs/StyledTextField'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined'
import { StyledLoadingButton } from '../../../../../styles/styledComponents/inputs/StyledLoadingButton'
import {
	showSuccessToast,
	timeout,
} from '../../../../../utils/helperFunctions/helperFunctions'
import { StyledButton } from '../../../../../styles/styledComponents/inputs/StyledButton'
import { StyledDialog } from '../../../../../styles/styledComponents/displays/StyledDialog'
import UseAPIHandler from '../../../../../utils/customHooks/APICalls/UseAPIHandler'
import { No_Customer } from '../../../../../utils/constants/constants'
import ClearIcon from '@mui/icons-material/Clear'

// ========================================================
// Purpose of Component:
// Manage role based email communication settings
// Basic Functionality:
// Allow users to add email addresses for specific roles
// ========================================================
const RoleCommunicationSettings = () => {
	// Global
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)
	const roleID = useSelector(
		(state: RootState) => state.RootReducer.roleIDReducer.value
	)
	const partnerID = useSelector(
		(state: RootState) => state.RootReducer.partnerIDReducer.value
	)

	// Hooks
	const { fetchData, modifyData } = UseCrud()
	const { handleAPICall } = UseAPIHandler()

	// Theme
	const theme = useTheme()

	// Display constants
	const [roleCommunicationList, setRoleCommunicationList] = useState(
		[] as RoleCommunicationEmails[]
	)
	const [dialogDescription, setDialogDescription] = useState('')

	// Logic constants
	const [contactDetailList, setContactDetailList] = useState(
		[] as ContactDetail[]
	)
	const [roleIDEmailToDelete, setRoleIDEmailToDelete] = useState(0)
	const [openDialog, setOpenDialog] = useState(false)
	const [modifyLoading, setModifyLoading] = useState(false)

	// Flag
	const [emailCallDone, setEmailCallDone] = useState(false)

	// Convert enum to array list = {Type, Value}
	const roleList = Object.keys(ContactDetailTypes)
		.filter((key) => isNaN(Number(key)))
		.map((key) => ({
			Type: key as keyof typeof ContactDetailTypes,
			Value: ContactDetailTypes[key as keyof typeof ContactDetailTypes],
		}))

	useEffect(() => {
		getRoleEmails()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// API Call: Get email addresses based on role. Default is partner admin
	const getRoleEmails = async () => {
		var whereClause = `ContactDetailMap.CustomerPartnerID = '${partnerID}'`
		if (roleID === Roles.CustomerAdmin) {
			whereClause = `ContactDetailMap.CustomerID = '${loggedInUser.customerID}'`
		}

		var requestURL = `GetV2?Params=ContactDetail.ContactDetailMap.Where(${whereClause} & ContactDetail.isRoleEmail = '1')`
		var dataResponse = (await fetchData({
			FileAndFunctionName: 'RoleCommunicationSettings: getRoleEmails()',
			QueryURL: requestURL,
			ErrorMessage: 'An error occurred when getting information',
			ShowErrorToast: false,
			LogErrorToDB: true,
			ReturnType: ReturnTypes.ObjectOrList,
		})) as DataResponse
		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var contactDetailListResponse = dataResponse.Obj
				.ContactDetailList as ContactDetail[]

			// Loop through roles to build display
			var roleCommunicationList = [] as RoleCommunicationEmails[]
			for (let r = 0; r < roleList.length; r++) {
				// Check if role email exists, else add a blank
				if (contactDetailListResponse) {
					var roleFound = contactDetailListResponse.find(
						(c) => Number(c.ContactDetailTypeID) === roleList[r].Value
					)
					if (roleFound) {
						roleCommunicationList.push({
							RoleID: roleList[r].Value,
							Email: roleFound.ContactPersonEmail,
							Synced: true,
						})
					} else {
						roleCommunicationList.push({
							RoleID: roleList[r].Value,
							Email: '',
							Synced: false,
						})
					}
				} else {
					roleCommunicationList.push({
						RoleID: roleList[r].Value,
						Email: '',
						Synced: false,
					})
				}
			}

			setRoleCommunicationList(roleCommunicationList)
			setContactDetailList(contactDetailListResponse)
		}

		await timeout(1000)
		setEmailCallDone(true)
	}

	// API Call: Delete access key
	const deleteRoleEmail = async () => {
		// Get ID from the contact list
		var roleEmail = contactDetailList.find(
			(c) => Number(c.ContactDetailTypeID) === roleIDEmailToDelete
		)
		if (roleEmail) {
			// Delete contact detail and map
			var deleteContactDetailMapObj: ContactDetailMap = {
				ContactDetailID: roleEmail.ContactDetailID,
			}
			var deleteContactDetailObj: ContactDetail = {
				ContactDetailID: roleEmail.ContactDetailID,
			}
			var deleteObj = {
				ContactDetailMap: deleteContactDetailMapObj,
				ContactDetail: deleteContactDetailObj,
			}

			var emailDeleted = await modifyData({
				FileAndFunctionName: 'RoleCommunicationSettings: deleteRoleEmail()',
				QueryURL: `DeleteV2?Params=ContactDetailMap,ContactDetail`,
				ErrorMessage: 'An error occurred when adding API Access key to DB',
				QueryObj: deleteObj,
				LogErrorToDB: true,
				UserName: loggedInUser.email,
				SuccessMessage: `${
					ContactDetailTypes[Number(roleEmail.ContactDetailTypeID)]
				} email address has been deleted successfully`,
				ShowErrorMessage: true,
				ShowSuccessMessage: true,
			})
			if (emailDeleted) {
				getRoleEmails()
			}
		}

		// Clear all delete constants
		handleClearDelete()
	}

	// API Call: Add and/or update role email addresses
	const modifyRoleEmails = async () => {
		setModifyLoading(true)

		// First check which emails need to be added and updated and perform two operations if needed
		var addList = [] as ContactDetail[]
		var updateList = [] as ContactDetail[]
		for (let r = 0; r < roleCommunicationList.length; r++) {
			// Ensure email has a value
			var roleEmail = roleCommunicationList[r].Email
			if (roleEmail && roleEmail.length > 0) {
				var roleFound = contactDetailList.find(
					(c) =>
						Number(c.ContactDetailTypeID) ===
						Number(roleCommunicationList[r].RoleID)
				)
				if (roleFound) {
					// Push to update list if emails are different
					if (roleFound.ContactPersonEmail !== roleCommunicationList[r].Email) {
						updateList.push({
							ContactDetailID: roleFound.ContactDetailID,
							ContactPersonEmail: roleCommunicationList[r].Email,
						})
					}
				} else {
					// Push to add list
					addList.push({
						ContactDetailTypeID: roleCommunicationList[r].RoleID,
						ContactPersonEmail: roleCommunicationList[r].Email,
						IsRoleEmail: true,
					})
				}
			}
		}

		// Perform relevant actions
		var modifySuccess: boolean | null | void = null
		if (addList.length > 0) {
			// Add to DB then return IDs for mapping
			var addEmailsObj = {
				ContactDetailList: addList,
			}

			var dataResponse = await handleAPICall<DataResponse>({
				Action: 'Add Role Email Addresses',
				RequestURL: `AddV2?Params=ContactDetail:list`,
				RequestObj: addEmailsObj,
				IsMutations: true,
				ShowSuccessMessage: false,
				ShowErrorMessage: false,
				ErrorMessage: 'An error occurred when adding role emails',
				LogErrorToDB: true,
			})
			if (dataResponse && dataResponse.Obj) {
				var addedContactDetailResponse = dataResponse.Obj
					.ContactDetailList as ContactDetail[]

				if (
					addedContactDetailResponse &&
					addedContactDetailResponse.length > 0
				) {
					// Perform mapping
					var contactDetailMapList = [] as ContactDetailMap[]
					for (let a = 0; a < addedContactDetailResponse.length; a++) {
						contactDetailMapList.push({
							ContactDetailID: addedContactDetailResponse[a].ContactDetailID,
							CustomerPartnerID:
								roleID === Roles.PartnerAdmin ? partnerID : Partners.No_Partner,
							CarrierID: 1,
							CustomerID:
								roleID === Roles.PartnerAdmin
									? No_Customer
									: loggedInUser.customerID,
						})
					}

					var addContactMapObj = {
						ContactDetailMapList: contactDetailMapList,
					}

					modifySuccess = await modifyData({
						FileAndFunctionName:
							'RoleCommunicationSettings: modifyRoleEmails()',
						QueryURL: `AddV2?Params=ContactDetailMap:list`,
						ErrorMessage: 'An error occurred when adding role email mappings',
						QueryObj: addContactMapObj,
						LogErrorToDB: true,
						UserName: loggedInUser.email,
						SuccessMessage: 'Successfully added role email mappings',
						ShowErrorMessage: false,
						ShowSuccessMessage: false,
					})
				}
			}
		}
		if (updateList.length > 0) {
			// Update emails
			var updateEmailsObj = {
				ContactDetailList: updateList,
			}

			modifySuccess = await modifyData({
				FileAndFunctionName: 'RoleCommunicationSettings: modifyRoleEmails()',
				QueryURL: `UpdateV2?Params=ContactDetail:list`,
				ErrorMessage: 'An error occurred when updating role emails',
				QueryObj: updateEmailsObj,
				LogErrorToDB: true,
				UserName: loggedInUser.email,
				SuccessMessage: 'Successfully updated role emails',
				ShowErrorMessage: false,
				ShowSuccessMessage: false,
			})
		}

		if (modifySuccess) {
			showSuccessToast('All role emails have been modified successfully.')
			await getRoleEmails()
		}

		setModifyLoading(false)
	}

	// Handle functions
	// Delete of email, set roleID to delete and open dialog
	const handleDeleteRoleEmail = (roleID: number) => {
		setRoleIDEmailToDelete(roleID)
		setDialogDescription(
			`Are you sure you wish to delete the ${ContactDetailTypes[roleID]} email address?`
		)
		setOpenDialog(true)
	}

	// Clear delete constants after delete or cancel
	const handleClearDelete = () => {
		setRoleIDEmailToDelete(0)
		setDialogDescription('')
		setOpenDialog(false)
	}

	// Handle change of email per role
	const handleEmailChange = (roleID: number, value: string) => {
		// Check for role and if different then set isChanged to true for tracking of icon buttons
		var emailChangeStatus = 0
		if (value.length > 0) {
			if (
				contactDetailList.find(
					(c) =>
						Number(c.ContactDetailTypeID) === roleID &&
						c.ContactPersonEmail !== value
				)
			) {
				emailChangeStatus = FormChangeStatus.UpdatedValue
			} else {
				emailChangeStatus = FormChangeStatus.NewValue
			}
		}

		// Set new value
		setRoleCommunicationList((prev) =>
			prev.map((item) =>
				Number(item.RoleID) === roleID
					? { ...item, Email: value, ChangeStatus: emailChangeStatus }
					: item
			)
		)
	}

	// Handle revert change
	const handleRevertChange = (roleID: number) => {
		// Check if new or updated
		var roleFound = roleCommunicationList.find(
			(r) => Number(r.RoleID) === roleID
		)
		if (roleFound) {
			// Get old record and revert change
			var oldRecord = contactDetailList.find(
				(c) => Number(c.ContactDetailTypeID) === roleID
			)
			if (oldRecord) {
				// Set old value from DB
				setRoleCommunicationList((prev) =>
					prev.map((item) =>
						Number(item.RoleID) === roleID
							? {
									...item,
									Email: oldRecord?.ContactPersonEmail,
									ChangeStatus: FormChangeStatus.NoChange,
							  }
							: item
					)
				)
			} else {
				// Clear value
				setRoleCommunicationList((prev) =>
					prev.map((item) =>
						Number(item.RoleID) === roleID
							? {
									...item,
									Email: '',
									ChangeStatus: FormChangeStatus.NoChange,
							  }
							: item
					)
				)
			}
		}
	}

	// Handle disable of button
	const disableButton = () => {
		var disableButton = true

		// Check both lists to see if anything has changed
		for (let e = 0; e < roleCommunicationList.length; e++) {
			// Check if new email added or existing email updated
			var email = roleCommunicationList[e].Email
			if (email && email.length > 0) {
				var roleEmailFound = contactDetailList.find(
					(c) =>
						Number(c.ContactDetailTypeID) ===
						Number(roleCommunicationList[e].RoleID)
				)
				if (roleEmailFound) {
					// Existing email
					if (
						roleEmailFound.ContactPersonEmail !== roleCommunicationList[e].Email
					) {
						disableButton = false
					}
				} else {
					// If adding a new one
					disableButton = false
				}
			}
		}

		return disableButton
	}

	return (
		<>
			<Box component='section'>
				{emailCallDone ? (
					<>
						{/* Dialog for deleting */}
						<StyledDialog
							open={openDialog}
							aria-labelledby='alert-dialog-title'
							aria-describedby='alert-dialog-description'>
							<DialogTitle id='alert-dialog-title'>
								Delete Email Address
							</DialogTitle>
							<DialogContent>
								<DialogContentText id='alert-dialog-description'>
									{dialogDescription}
								</DialogContentText>
							</DialogContent>
							<Divider />
							<DialogActions>
								<StyledButton
									onClick={handleClearDelete}
									sx={{
										background: theme.palette.error.main,
										':hover': {
											background: theme.palette.error.main,
										},
									}}>
									Cancel
								</StyledButton>
								<StyledButton onClick={deleteRoleEmail} autoFocus>
									Confirm
								</StyledButton>
							</DialogActions>
						</StyledDialog>
						{/* Section Description */}
						<Box className='section-desc'>
							<Typography component='h4'>
								Role communication settings
							</Typography>
							<Typography sx={{ color: '#808080' }} component='p'>
								Set the e-mail addresses for specific role required
								communication.
							</Typography>
						</Box>
						{/* Content */}
						<Box className='section-form'>
							{/* Heading */}
							<Box className='form-table'>
								<Box>
									<Typography component='h4'>Role Type</Typography>
								</Box>
								<Box>
									<Typography component='h4'>Email Address</Typography>
								</Box>
							</Box>
							<Divider />
							{/* Array - View/Add/Delete role emails */}
							{roleCommunicationList.map((item, index) => (
								<Box className='form-table'>
									<Box className='lbl-flex'>
										<Typography key={index}>
											{ContactDetailTypes[Number(item.RoleID)]}
										</Typography>
									</Box>
									<StyledTextField
										key={index}
										fullWidth
										value={item.Email}
										onChange={(e) =>
											handleEmailChange(Number(item.RoleID), e.target.value)
										}
										InputProps={{
											endAdornment: item.Email && item.Email.length > 0 && (
												<InputAdornment position='end'>
													{Number(item.ChangeStatus) > 0 && (
														<Tooltip title='Revert changes'>
															<IconButton
																aria-label='Revert Email Address Changes'
																onClick={() =>
																	handleRevertChange(Number(item.RoleID))
																}>
																{Number(item.ChangeStatus) === 1 ? (
																	<ClearIcon />
																) : (
																	<ReplayOutlinedIcon />
																)}
															</IconButton>
														</Tooltip>
													)}
													{item.Synced && (
														<Tooltip
															title={`Delete ${
																ContactDetailTypes[Number(item.RoleID)]
															} Email Address`}>
															<IconButton
																aria-label='Delete Role Email Address'
																onClick={() =>
																	handleDeleteRoleEmail(Number(item.RoleID))
																}>
																<DeleteOutlineOutlinedIcon />
															</IconButton>
														</Tooltip>
													)}
												</InputAdornment>
											),
										}}
									/>
								</Box>
							))}
							{/* Button to save and update changes */}
							<Box className='btn'>
								<StyledLoadingButton
									disabled={disableButton()}
									loading={modifyLoading}
									onClick={modifyRoleEmails}>
									Save Changes
								</StyledLoadingButton>
							</Box>
						</Box>
					</>
				) : (
					<>
						{/* Skeleton loading */}
						<Box className='section-desc'>
							<Skeleton variant='text' width={150} height={40} />
							<Skeleton variant='text' width='60%' height={20} />
						</Box>
						<Box className='section-form'>
							<Box className='form-table'>
								<Skeleton variant='text' width={100} height={30} />
								<Skeleton variant='text' width={100} height={40} />
							</Box>
							<Divider />
							{/* Repeat for each roleCommunicationList item */}
							{Array.from({ length: 5 }).map((_, index) => (
								<Box key={index} className='form-table'>
									<Skeleton variant='text' width={100} height={30} />
									<Skeleton variant='rectangular' width='100%' height={40} />
								</Box>
							))}
							{/* Save Button Skeleton */}
							<Skeleton variant='rectangular' width={120} height={40} />
						</Box>
					</>
				)}
			</Box>
		</>
	)
}

export default RoleCommunicationSettings
