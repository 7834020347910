import { Dialog, styled } from '@mui/material'

export const StyledDialog = styled(Dialog)(({ theme }) => ({
	backgroundColor: 'rgba(0, 0, 0, 0.05)',
	h2: {
		fontSize: '20px!important',
		padding: '20px 20px 10px 20px',
	},
	'.MuiDialogContent-root': {
		padding: '10px 20px 20px 20px',

		p: {
			color: `${theme.palette.text.primary}!important`,
		},
	},
	'.MuiDialogActions-root': {
		padding: '20px',
	},
}))
