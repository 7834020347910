import {
	Box,
	IconButton,
	InputAdornment,
	Skeleton,
	Typography,
	useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { ReturnTypes } from '../../../../../utils/enums/enums'
import { DataResponse } from '../../../../../utils/interfaces/APIModels'
import { CustomerAPIKey } from '../../../../../utils/interfaces/DBModels'
import UseCrud from '../../../../../utils/customHooks/APICalls/UseCrud'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import {
	copyToClipboard,
	showSuccessToast,
	timeout,
	toAlphaString,
	toBetaString,
} from '../../../../../utils/helperFunctions/helperFunctions'
import { StyledTextField } from '../../../../../styles/styledComponents/inputs/StyledTextField'
import { StyledLoadingButton } from '../../../../../styles/styledComponents/inputs/StyledLoadingButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DoneIcon from '@mui/icons-material/Done'

const CustomerAPIKeySection = () => {
	// Global
	const loggedInUser = useSelector(
		(state: RootState) => state.RootReducer.loggedInUserReducer.value
	)

	// Theme
	const theme = useTheme()

	// Hooks
	const { fetchData, modifyData } = UseCrud()

	// Flags
	const [keyCallDone, setKeyCallDone] = useState(false)

	// Form constants
	const [loading, setLoading] = useState(false)
	const [showAccessKey, setShowAccessKey] = useState(false)
	const [keyCopied, setKeyCopied] = useState(false)
	const [keyValue, setKeyValue] = useState('')

	// ID to use for deleting
	const [customerAPIKeyID, setCustomerAPIKeyID] = useState(0)

	useEffect(() => {
		// Make call to check if customer has key
		getCustomerAccessKey()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// API Call: Check for key
	const getCustomerAccessKey = async () => {
		// Get the users key if it exists
		if (loggedInUser.customerID && loggedInUser.customerID.length > 0) {
			var dataResponse = (await fetchData({
				FileAndFunctionName: 'CustomerAPIKeySection: getCustomerAccessKey()',
				QueryURL: `GetV2?Params=CustomerAPIKey.First(CustomerAPIKey.CustomerID = '${loggedInUser.customerID}')`,
				ErrorMessage: 'An error occurred when getting information',
				ShowErrorToast: false,
				LogErrorToDB: true,
				ReturnType: ReturnTypes.ObjectOrList,
			})) as DataResponse
			if (dataResponse && dataResponse.Obj) {
				var customerAPIKeyObj = dataResponse.Obj
					.CustomerAPIKey as CustomerAPIKey
				if (customerAPIKeyObj && customerAPIKeyObj.APIKey) {
					// Decrypt the key and assign
					var decryptedKey = await toAlphaString(customerAPIKeyObj.APIKey)
					if (decryptedKey) {
						setKeyValue(decryptedKey)
					}

					// Set ID for delete purposes
					setCustomerAPIKeyID(Number(customerAPIKeyObj.CustomerAPIKeyID))
				}
			}
		}

		await timeout(1000)
		setKeyCallDone(true)
	}

	// API Call: Add new key to DB
	const addCustomerAccessKey = async (key: string) => {
		// Encrypt the key first then add to DB
		var customerAPIKeyObj: CustomerAPIKey = {
			CustomerID: loggedInUser.customerID,
			APIKey: toBetaString(key),
		}
		var addCustomerKeyObj = {
			CustomerAPIKey: customerAPIKeyObj,
		}

		var keyAdded = await modifyData({
			FileAndFunctionName: 'CustomerAPIKeySection: addCustomerAccessKey()',
			QueryURL: `AddV2?Params=CustomerAPIKey`,
			ErrorMessage: 'An error occurred when adding API Access key to DB',
			QueryObj: addCustomerKeyObj,
			LogErrorToDB: true,
			UserName: loggedInUser.email,
			SuccessMessage: 'API Access key has been generated successfully',
			ShowErrorMessage: true,
			ShowSuccessMessage: true,
		})
		if (keyAdded) {
			await getCustomerAccessKey()
		}
	}

	// API Call: Delete access key
	const deleteCustomerAccessKey = async () => {
		// Encrypt the key first then add to DB
		var customerAPIKeyObj: CustomerAPIKey = {
			CustomerAPIKeyID: customerAPIKeyID,
		}
		var addCustomerKeyObj = {
			CustomerAPIKey: customerAPIKeyObj,
		}

		var keyDeleted = await modifyData({
			FileAndFunctionName: 'CustomerAPIKeySection: deleteCustomerAccessKey()',
			QueryURL: `DeleteV2?Params=CustomerAPIKey`,
			ErrorMessage: 'An error occurred when adding API Access key to DB',
			QueryObj: addCustomerKeyObj,
			LogErrorToDB: true,
			UserName: loggedInUser.email,
			SuccessMessage: 'API Access key has been deleted successfully',
			ShowErrorMessage: true,
			ShowSuccessMessage: true,
		})
		if (keyDeleted) {
			setCustomerAPIKeyID(0)
			setKeyValue('')
			setShowAccessKey(false)
		}
	}

	// Generate key - It will look like a function key i.e. xX0x0X0X-0x3x7x-9X3xXxY1xX9x3X5xZ0XN1A3x==
	const generateAccessKey = (): string => {
		const charset =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
		let key = ''

		for (let i = 0; i < 32; i++) {
			const randomIndex = Math.floor(Math.random() * charset.length)
			key += charset[randomIndex]
		}

		const numDashes = 2
		for (let i = 0; i < numDashes; i++) {
			const dashIndex = Math.floor(Math.random() * (key.length + 1))
			key = key.slice(0, dashIndex) + '-' + key.slice(dashIndex)
		}

		return key + '=='
	}

	// Handle generation of access key
	const handleAccessKeyAction = async () => {
		setLoading(true)

		// Check if add or delete
		if (keyValue.length > 0) {
			// Delete key
			await deleteCustomerAccessKey()
		} else {
			// Generate key then add to the DB
			var accessKey = generateAccessKey()
			if (accessKey) {
				await addCustomerAccessKey(accessKey)
			}
		}

		// Stop loading
		setLoading(false)
	}

	// Handle copy to clipboard
	const handleCopyToClipboard = async () => {
		await copyToClipboard(keyValue)
		setKeyCopied(true)
		showSuccessToast('Access key copied to clipboard.')
		await timeout(2000)
		setKeyCopied(false)
	}

	return (
		<>
			<Box component='section'>
				{keyCallDone ? (
					<>
						{/* Section Description */}
						<Box className='section-desc'>
							<Typography component='h4'>API Access:</Typography>
							<Typography sx={{ color: '#808080' }} component='p'>
								{keyValue.length > 0
									? 'Copy your API key to access the Customer HALO API. Click on the button to delete your access key.'
									: 'Click on the button to get API access. A key will be generated and stored.'}
							</Typography>
						</Box>
						{/* Content */}
						<Box className='section-content'>
							{/* Toggle */}
							<StyledTextField
								disabled
								fullWidth
								type={showAccessKey ? 'text' : 'password'}
								placeholder='API Access Key'
								value={keyValue}
								InputProps={{
									endAdornment: keyValue.length > 0 && (
										<InputAdornment position='end'>
											<IconButton
												aria-label={
													showAccessKey ? 'Hide access key' : 'Show access key'
												}
												onClick={() => setShowAccessKey((show) => !show)}>
												{showAccessKey ? (
													<VisibilityOffIcon />
												) : (
													<VisibilityIcon />
												)}
											</IconButton>
											<IconButton
												disabled={keyCopied}
												aria-label='Copy access key'
												onClick={handleCopyToClipboard}>
												{keyCopied ? <DoneIcon /> : <ContentCopyIcon />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							{keyCallDone && (
								<Box className='btn'>
									<StyledLoadingButton
										disabled={loading}
										variant='contained'
										onClick={handleAccessKeyAction}
										sx={{
											background:
												keyValue.length > 0
													? theme.palette.error.main
													: theme.palette.primary.main,
											':hover': {
												background:
													keyValue.length > 0
														? theme.palette.error.main
														: theme.palette.primary.main,
											},
										}}>
										{keyValue.length > 0
											? 'Delete Access Key'
											: 'Generate Access Key'}
									</StyledLoadingButton>
								</Box>
							)}
						</Box>
					</>
				) : (
					<>
						<Box className='section-desc'>
							<Skeleton variant='text' width={150} height={40} />
							<Skeleton variant='text' width='100%' height={20} />
						</Box>
						<Box className='section-content'>
							<Skeleton variant='rectangular' width='100%' height={56} />
							<Box className='btn'>
								<Skeleton variant='rectangular' width={160} height={40} />
							</Box>
						</Box>
					</>
				)}
			</Box>
		</>
	)
}

export default CustomerAPIKeySection
